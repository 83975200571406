<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="AgentAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addAgent">
        <!-- Name -->
        <b-form-group label="Scanner Name" label-for="Scanner Name">
          <validation-provider
            #default="{ errors }"
            name="agent_name"
            :rules="{ regex: /^[a-zA-Z0-9\s\@\_\-]+$/ }"
          >
            <b-form-input
              id="agent_name"
              v-model="agent_name"
              :state="errors.length > 0 ? false : null"
              name="agent_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- End Point -->
        <b-form-group label="Scanner End Point" label-for="Scanner End Point">
          <validation-provider
            #default="{ errors }"
            name="agent_end_point"
            rules="required"
          >
            <b-form-input
              id="agent_end_point"
              v-model="agent_end_point"
              :state="errors.length > 0 ? false : null"
              name="agent_end_point"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Scanner Type-->
        <b-form-group label="Scanner Type" label-for="Scanner Type">
          <validation-provider
            #default="{ errors }"
            name="agent_type"
            rules="required"
          >
            <b-form-select v-model="agent_type" :options="agent_types">
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Type-->
        <b-form-group label="Type" label-for="Type">
          <validation-provider
            #default="{ errors }"
            name="asset_type"
            rules="required"
          >
            <b-form-select
              v-model="asset_type_id"
              :options="asset_type_options"
              :state="errors.length > 0 ? false : null"
              @change="onTypeChange"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Agent Group" label-for="Agent Group">
          <validation-provider
            #default="{ errors }"
            name="agent_group"
            rules="required"
          >
            <b-form-select
              v-model="agent_group_type"
              :options="agentGroupTypes"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Organization-->
        <b-form-group
          label="Organization"
          label-for="Organization"
          v-if="agent_group_type === 'internal'"
        >
          <b-form-select
            v-model="organization"
            :options="organizations"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-if="agent_group_type === 'internal'">
          <template #label>
            <span>Network Location</span>
            <b-button
              class="ml-1"
              style="padding: 0.186rem 1rem !important"
              @click="gotoCreateNetwork()"
              variant="outline-success"
              size="sm"
              >+ Add Network Location</b-button
            >
          </template>
          <v-select
            v-model="networkLocation"
            label="text"
            placeholder="--Select Network Location--"
            :options="networkLocationOptions"
            autocomplete
            :reduce="(networkLocation) => networkLocation.value"
            :clearable="false"
          />
        </b-form-group>

        <b-form-group
          label="Scanner Description"
          label-for="Scanner Description"
        >
          <b-form-textarea
            id="agent_description"
            v-model="agent_description"
            name="agent_description"
            rows="3"
            max-rows="3"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
        </b-form-group>

        <b-form-group label="Scanner Meta Input" label-for="Default Meta Input">
          <validation-provider
            #default="{ errors }"
            name="agent_meta"
            rules="required"
          >
            <b-form-textarea
              id="agent_meta"
              :state="errors.length > 0 ? false : null"
              v-model="agent_meta"
            ></b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="my-1"
          size="sm"
        >
          Add
        </b-button>
      </b-form>
    </validation-observer>
     <b-modal ref="modal-new-network-location" title="Create New Network Location" hide-footer size="lg">
     <AddNetworkLocation :redirectNetwork="redirectNetwork" :closeSidebar="closeNetworkModal"/>
    </b-modal>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
  BModal
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'
import vSelect from "vue-select";
import AddNetworkLocation from "../NetworkLocation/add.vue"
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    vSelect,
    BModal,
    AddNetworkLocation
  },
  data() {
    return {
      agent_name: "",
      agent_description: "",
      agent_end_point: "",
      agent_meta: "",
      agent_group_type: null,
      agentGroupTypes: [
        { value: null, text: "--Select Agent Group Type--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "External" },
      ],
      agent_type: null,
      agent_types: [{ value: null, text: "--Select Agent Type--" }],
      asset_type_id: null,
      asset_type_options: [{ value: null, text: "--Select Asset Type--" }],
      organization: "",
      organizations: [{ value: null, text: "--Select Organization--" }],
      networkLocationOptions: [],
      networkLocation: null,
      redirectNetwork:true,
    };
  },
  watch: {
    agent_group_type: function (newAssetGroupType, oldAssetGroupType) {
      if (newAssetGroupType === "internal") {
        this.loadnetworkLocation();
      }
    },
  },
  created: function () {
    this.load();
    this.loadAgentTypes();
    this.loadOrganization();
  },
  methods: {
    load: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/asset-type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let type = {
            value: res.data[key].asset_type_id,
            text: res.data[key].asset_type_name,
          };
          // console.log(res.data[key].profile.organization)
          self.asset_type_options.push(type);
        });
      });
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let type = {
            value: res.data[key].agent_type_id,
            text: res.data[key].agent_type_nicename,
          };
          // console.log(res.data[key].profile.organization)
          self.agent_types.push(type);
        });
      });
    },
    loadOrganization: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/get-all-org/",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let org = {
            value: res.data[key].org_id,
            text: res.data[key].org_name,
          };
          // console.log(res.data[key].profile.organization)
          self.organizations.push(org);
        });
      });
    },
    addAgent: function () {
      this.$refs.AgentAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          console.log("success");
          let data = {
            agent_name: this.agent_name,
            agent_description: this.agent_description,
            agent_end_point: this.agent_end_point,
            agent_meta: this.agent_meta,
            agent_type_id: this.agent_type,
            asset_type_id: this.asset_type_id,
            agent_group: this.agent_group_type,
            organization: this.organization,
          };
          if (this.agent_group_type == "internal") {
            data.network_location = this.networkLocation;
          }
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "agent/agent/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.agent_id) {
              this.$router.push({ name: "Scanners" });
            }
          });
        }
      });
    },
    loadnetworkLocation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/network-location/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.networkLocationOptions = res.data.map((item) => ({
          value: item.id,
          text: item.title,
        }));

        // Find the index of the item with text "DEFAULT"
        const defaultIndex = this.networkLocationOptions.findIndex(
          (item) => item.text === "DEFAULT"
        );

        // If "DEFAULT" is found, set it as the default selection
        if (defaultIndex !== -1) {
          this.networkLocation =
            this.networkLocationOptions[defaultIndex].value;
        } else if (this.networkLocationOptions.length > 0) {
          // If "DEFAULT" is not found, fallback to selecting the first item
          this.networkLocation = this.networkLocationOptions[0].value;
        }
      });
    },
    gotoCreateNetwork() {
      this.$refs["modal-new-network-location"].show();
    },
    closeNetworkModal(){
      this.$refs["modal-new-network-location"].hide();
      this.loadnetworkLocation();
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>